import React from "react";
import SinglePartner from "./SinglePartner";



const shuffleArray = array => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
    return array;
}



const ListPartnersView = (props) => {
    //let itemsToDisplay = props.data;
    let itemsToDisplay = shuffleArray(props.data)


    return (
        <>
            {itemsToDisplay.map((item) => (
                <SinglePartner data={item} key={item.index} />
            ))}
        </>
    );
}

export default ListPartnersView