import React from "react";

function Footer() {
    return (
        <footer className="footer mt-5 py-3" role="contentinfo">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12 col-sm-8 col-md-7 col-lg-5">
                        <a href="https://www.taboola.com/policies/privacy-policy" className="footer-link text-dark" target="_blank" rel="noreferrer">Privacy Policy</a>
                        <a href="https://www.taboola.com/policies/cookie-policy" className="footer-link text-dark" target="_blank" rel="noreferrer">Cookie Policy</a>
                        <a href="https://www.taboola.com/policies/terms-and-conditions" className="footer-link text-dark" target="_blank" rel="noreferrer">Terms and Conditions</a>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer
