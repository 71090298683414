import React, { useState } from "react";
import "./assets/styles/styles.scss";
import Header from "./components/static/Header";
import Footer from "./components/static/Footer";
import Hero from "./components/static/Hero";
import DisplayContainer from "./components/DisplayContainer";
import ContentDataLayer from "./services/ContentDataLayer";





function App() {
  const [dataLayer] = useState(ContentDataLayer(), [])

  return (
    <div className="App">
      <Header />
      <Hero />

      <DisplayContainer dataLayer={dataLayer} />
      <Footer />

    </div>
  );
}

export default App;
