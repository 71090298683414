import React, { useState } from 'react';
import * as Bootstrap from "react-bootstrap";
import Button from "./Button";
import Spinner from "./Spinner";

const inputFieldsData = [
    { label: 'First And Last Name', name: 'fullName', type: 'text', required: true, validationText: 'Please provide a First And Last Name.' },
    // { label: 'Last Name', name: 'lastName', type: 'text', required: true, validationText: 'Please provide a Last Name.' },
    { label: 'Email Address', name: 'email', type: 'email', required: true, validationText: 'Please provide a valid Email.' },
    { label: 'Company Name', name: 'companyName', type: 'text', required: true, validationText: 'Please provide a Company Name.' },
    { label: 'Company Website', name: 'companyWebsite', type: 'text', required: true, validationText: 'Please provide a valid URL' },
    { label: 'Company HQ Country', name: 'companyHqCountry', type: 'text', required: true, validationText: 'Please provide a Company HQ Country' },
    { label: 'Company HQ City', name: 'companyHqCity', type: 'text', required: true, validationText: 'Please provide a Company HQ City' },
    { label: 'Monthly Media Budget (Approximate)', name: 'MonthlyMediaBudget', type: 'text', required: true, validationText: 'Please provide a Monthly Media Budget' }
]
const industries = ['Affiliation Marketing', 'Automotive', 'Consumer packaged goods', 'Content Promotion', 'eCommerce', 'Education', 'Energy & Utilities', 'Entertainment', 'Financial Services', 'Gaming', 'Healthcare', 'Real Estate', 'Technology', 'Telecom', 'Travel'];
const focusedMarketingObjectives = ['Brand awareness', 'Content Arb', 'Online Sales', 'Lead Generation', 'Mobile App installs', 'Page views (website engagement)', 'Search Arb'];
//const preferredAgencyLocation = ['France', 'Germany', 'Italy', 'Israel', 'Scandinavians', 'UK', 'US - East', 'US - Central', 'US - West', 'I have no preference'];
const agreement = 'I understand and agree that partners may contact me about products, services, and special offers and that my information will be subject to their privacy policy. Additionally, I acknowledge and agree that the partner may share certain interaction information back to Taboola, including status of your outreach and lead conversion. Any information shared back to Taboola and Taboola’s use of such data will be done subject to Taboola’s Data Policy.';


const Modal = props => {
    const [validated, setValidated] = useState(false);
    const [submissionMode, setSubmissionMode] = useState('idle')

    function handleSubmit(e) {
        const formData = new FormData(e.currentTarget);
        e.preventDefault();
        e.stopPropagation();
        if (e.currentTarget.checkValidity() === false) {
            setValidated(true);
            return;
        }

        const data = new URLSearchParams();

        formData.forEach((value, key) => data.append(key, value));
        console.log('passed validation!');

        setSubmissionMode('submitting');

        fetch(`https://helpersng.taboola.com/swissknife-code/submit-mrkt-partners/index.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: data,
        })
            .then(res => res.json())
            .then(data => {
                console.log('Success:', data);
                setSubmissionMode('sent');
                setTimeout(() => {
                    props.toggleModal();
                }, 2000)
            })
            .catch((error) => {
                console.error('Error:', error);
            });

    }
    return (
        <>
            <Bootstrap.Modal
                show={props.isOpen}
                onHide={props.toggleModal}
                fullscreen={true}
                dialogClassName="form-modal "
                animation="true"
                aria-labelledby="Contact Form"
            >
                <Bootstrap.Container>
                    <Bootstrap.Modal.Body>

                        <div className='row'>
                            <div className='col-12 text-center'>
                                <h1 className='color-happy-blue font-weight-bold font-size-md mb-3 mb-md-5'>Contact a Taboola Partnered Agency</h1>
                            </div>
                        </div>
                        <Bootstrap.Form className="row contact-form d-flex align-items-stretch" id='form' noValidate validated={validated} onSubmit={handleSubmit}>
                            <div className="col-md-4">
                                <input type="hidden" value={props.companyName} name="company-name" />
                                <div className='box-shadow p-4 ' id="form-inputs">
                                    {
                                        inputFieldsData.map((e, i) => {
                                            return <Bootstrap.FloatingLabel
                                                controlId={e.name}
                                                label={e.label}
                                                className="pt-3 "
                                                key={e.name + i}
                                            >
                                                <Bootstrap.Form.Control
                                                    type={e.type}
                                                    name={e.name}
                                                    placeholder={e.label}
                                                    required={e.required ? 'required' : ''} s
                                                />
                                                <Bootstrap.Form.Control.Feedback type="invalid">
                                                    {e.validationText}
                                                </Bootstrap.Form.Control.Feedback>

                                            </Bootstrap.FloatingLabel>
                                        })}
                                </div>
                                <div className="box-shadow p-4 mt-3">
                                    <Bootstrap.FloatingLabel
                                        controlId='accountID'
                                        label='Taboola Account ID (if you do not have an active account, please mention "none")'
                                        className="pt-4"
                                    >
                                        <Bootstrap.Form.Control
                                            type='text'
                                            name='accountID'
                                            placeholder='Taboola Account ID (if you do not have an active account, please mention "none")'
                                            required="true"
                                        />
                                        <Bootstrap.Form.Control.Feedback type="invalid">
                                            Please provide an Taboola Account ID or type 'none'
                                        </Bootstrap.Form.Control.Feedback>
                                    </Bootstrap.FloatingLabel>
                                </div>
                                <div className="box-shadow p-4 mt-3">
                                    <Bootstrap.FloatingLabel
                                        controlId='helpwith'
                                        label='What do you need help with?'
                                        className="pt-4"
                                    >
                                        <Bootstrap.Form.Control
                                            type='text'
                                            name='helpwith'
                                            placeholder='What do you need help with?'
                                            required="true"
                                        />
                                        <Bootstrap.Form.Control.Feedback type="invalid">
                                            Please Fill up this field
                                        </Bootstrap.Form.Control.Feedback>
                                    </Bootstrap.FloatingLabel>
                                </div>
                            </div>
                            <div className='col-12 col-md-8 mt-3 mt-md-0'>
                                <div className='row px-4 px-md-4 py-3 py-md-5 box-shadow h-100'>
                                    <div className='col-12 col-md-6 mb-4 mb-md-0'>
                                        <h6 className='mb-2 mb-md-4' >Industries</h6>
                                        {industries.map((item, index) => (
                                            <div key={`industries-${index}`} className="mb-2">
                                                <Bootstrap.Form.Check
                                                    type="checkbox"
                                                    id={`${item}`}
                                                    label={`${item}`}
                                                    name={`industries_${item.toLowerCase().replaceAll(" ", "_")}`}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                    <div className='col-12 col-md-6 mb-4 mb-md-0'>
                                        <div className=' mb-5'>

                                            <h6 className='mb-2 mb-md-4'>Focused Marketing Objectives</h6>
                                            {focusedMarketingObjectives.map((item, index) => (
                                                <div key={`focusedMarketingObjectives-${index}`} className="mb-2">
                                                    <Bootstrap.Form.Check
                                                        type="checkbox"
                                                        id={`${item}`}
                                                        label={`${item}`}
                                                        name={`focusedMarketingObjectives_${item.toLowerCase().replaceAll(" ", "_")}`}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className='col-12' id="agreement-wrap">
                                        <h6 className='mt-2'>Please Accept (Mandatory)</h6>
                                        <div key="agreement" className="mb-3">
                                            <Bootstrap.Form.Check
                                                type="checkbox"
                                                id="agreement"
                                                label={`${agreement}`}
                                                name="agreement"
                                                required
                                                feedback="You must agree before submitting."
                                                feedbackType="invalid"
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className='col-12 mt-5 d-flex justify-content-between'>
                                <Button variant="link" onClick={props.toggleModal} text="Clear"></Button>
                                <Button Button variant="primary" type="submit" >
                                    {submissionMode === 'submitting' ? <> <Spinner></Spinner> Submitting</> : undefined}
                                    {submissionMode === 'idle' ? <span>Submit</span> : undefined}
                                    {submissionMode === 'sent' ? <span> Sent!</span> : undefined}
                                </Button>
                            </div>
                        </Bootstrap.Form>
                    </Bootstrap.Modal.Body>
                </Bootstrap.Container>
            </Bootstrap.Modal>
        </>
    )
};

export default Modal;