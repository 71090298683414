import axios from 'axios';


function ContentDataLayer() {
  let fetchedData = null;
  const currentUrl = window.location.href;
  let fetchUrl = '';
  if (currentUrl.indexOf('localhost') > -1) {
    fetchUrl = 'http://dev.marketing-partners.local/wp-content/themes/marketing-partners/handlers/get_all_partners.php?data=all';
  }
  else {
    fetchUrl = '/wp-content/themes/marketing-partners/handlers/get_all_partners.php?data=all';
  }



  return {
    getContent() {
      if (fetchedData) { return Promise.resolve(fetchedData) }
      return axios.get(fetchUrl)
        .then((res) => {
          fetchedData = res.data;
          return res.data;

        })
        .catch((err) => {
          return err;
        });
    }
  }
}


export default ContentDataLayer;