import React, { useState } from "react";
import * as Bootstrap from "react-bootstrap";
import Button from "./Button";
import Modal from "./Modal";
import { fieldNameToReadableName } from './../Helpers'

// load all default images as an object
function importAllDefaultImages(r) {
    let images = {}
    r.keys().map((item, index) => {
        return images[item.replace('./', '')] = r(item);
    });
    return Object.values(images);
}
const defaultImages = importAllDefaultImages(require.context('./../assets/images/default-images/', false, /\.(png|jpe?g|svg)$/));

function SinglePartner(props) {

    const [modalState, setModalState] = useState(false);
    const [modalKey, setModalKey] = useState(Math.random())

    const toggleModal = () => {
        setModalState(!modalState);
        if (!modalState) {
            setModalKey(Math.random())
        }
    };


    const industries = props.data.categories.industries;

    const industriesList = [];
    //used for loop instead of map to break after 4 items
    for (let i = 0; i < industries.length; i++) {
        industriesList.push(<li key={i} className="mb-2">{fieldNameToReadableName(industries[i])}</li>);
        if (i === 3) {
            break;
        }
    }
    return (
        <Bootstrap.Row className="single-partner  pb-5 mb-5">
            <Bootstrap.Col className="col-12">
                <Bootstrap.Row className="">
                    <Bootstrap.Col className="col-3 col-md-2">
                        <figure className="single-partner__image">

                            { // get random default image
                                /* <img src={props.data.image.length > 0 ? props.data.image : defaultImages[Math.floor(Math.random() * defaultImages.length)]} alt={props.data.title} className="rounded" /> */
                            }
                            <img src={props.data.image.length > 0 ? props.data.image : defaultImages[0]} alt={props.data.title} className="rounded" />
                        </figure>
                    </Bootstrap.Col>
                    <Bootstrap.Col className="col-9 col-md-10 px-4 d-flex justify-content-between flex-column">
                        <h2 className="mb-1 m-md-0">{props.data.title}</h2>
                        <a href={props.data.url.url} className="url  d-block mb-2 m-md-0 d-inline-block" target="_blank" rel="noreferrer">{props.data.url.title}</a>
                        <ul className="mt-auto">
                            {industriesList}
                        </ul>
                    </Bootstrap.Col>
                </Bootstrap.Row>
                <Bootstrap.Row>
                    <Bootstrap.Col className="col-12 mt-3 line-break">
                        <p>{props.data.excerpt.replaceAll('<br />', '\n')}</p>
                    </Bootstrap.Col>
                </Bootstrap.Row>
                <Bootstrap.Row className="d-flex flex-row-reverse">
                    <Bootstrap.Col className="mt-4 col-12 col-md-auto">
                        <Button variant="primary" onClick={toggleModal} text="Contact"></Button>
                    </Bootstrap.Col>
                </Bootstrap.Row>
            </Bootstrap.Col>
            <Modal isOpen={modalState} toggleModal={toggleModal} itemId={props.data.id} key={modalKey} companyName={props.data.title} />


        </Bootstrap.Row>
    );
}

export default SinglePartner
