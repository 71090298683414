import React from "react";
import logo from "./../../assets/images/taboola-mp-logo.svg";

function Header() {
    return (
        <header className="header" role="heading">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-8  col-md-7 col-lg-5">
                        <img src={logo} className="logo mt-4" alt="Taboola Marketing Partners" title="Taboola Marketing Partners" />
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header
