import React from "react";
import DropDown from "./Dropdown";
import * as Bootstrap from "react-bootstrap";
import Button from "./Button";


const FilterView = (props) => {
    return (
        <>

            <div className="box-shadow filter py-3  px-4">

                <Bootstrap.Row className="justify-content-between">
                    <Bootstrap.Col className="col-12">
                        <div className="form-group search">
                            <span className="search-icon form-control-feedback"></span>
                            <input type="text" className="form-control" placeholder="Search"
                                onChange={(e) => props.onSearchChange(e.target.value)} />
                        </div>
                    </Bootstrap.Col>
                </Bootstrap.Row>
            </div>
            <div className="box-shadow filter pt-3 pb-5 px-4 mt-3">

                <Bootstrap.Row className=" pt-4 pb-4 justify-content-between">
                    <Bootstrap.Col className="col-4"><h5 className="color-dark-blue font-weight-bold  ">Filters</h5></Bootstrap.Col>
                    <Bootstrap.Col className="col-4">
                        <Button
                            text="Clear all"
                            variant="light"
                            onClick={(e => props.resetFilters())}
                            className="text--small"
                        />
                    </Bootstrap.Col>
                </Bootstrap.Row>
                <DropDown data={props.data.solutions} selected={props.selectedFilters.solutions} name={'solutions'} onChange={(e) => props.onChange('solutions', e)} />
                <DropDown data={props.data.countries} selected={props.selectedFilters.countries} name={'countries'} onChange={(e) => props.onChange('countries', e)} />
                <DropDown data={props.data.languages} selected={props.selectedFilters.languages} name={'languages'} onChange={(e) => props.onChange('languages', e)} />
                <DropDown data={props.data.outcomespecialities} selected={props.selectedFilters.outcomespecialities} name={'outcome specialities'} onChange={(e) => props.onChange('outcomespecialities', e)} />
                <DropDown data={props.data.industries} selected={props.selectedFilters.industries} name={'industries'} onChange={(e) => props.onChange('industries', e)} />
                <DropDown data={props.data.partnertype} selected={props.selectedFilters.partnertype} name={'partner type'} onChange={(e) => props.onChange('partnertype', e)} />

            </div>
        </>
    );
}
export default FilterView

