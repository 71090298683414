import React from "react";
import * as Bootstrap from "react-bootstrap";



export default function Button(props) {

    const { text, variant, type, className } = props;
    return (
        <Bootstrap.Button className={className} variant={variant} onClick={props.onClick} type={type ? type : ''}> {text ?? props.children}  </Bootstrap.Button>
    )
}