import React, { useState, useEffect } from "react";
import desktopImage from "./../../assets/images/hero.jpg";
import mobileImage from "./../../assets/images/heroMobile.jpg";


function Hero() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const backgroundImageUrl = windowWidth >= 650 ? desktopImage : mobileImage;

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        }
    }, []);
    return (
        <>
            <section className="background-cover-image hero" style={{ backgroundImage: `url(${backgroundImageUrl})` }}>
                <div className="container">
                    <div className="row height-md align-items-md-center pt-5 pt-md-0 ">
                        <div className="col-9 col-sm-6 col-md-7 col-lg-5">
                            <h1 className="text-uppercase text-white font-size-md mt-2"><span className="text-blue">Grow Your Business </span> with The Right Agency Partner </h1>
                            <p className="text-white font-size-lg line-height-4">Work with selected Taboola partners recognized for expertise with Taboola Advertising to optimize your campaigns and get you the results you need</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Hero;