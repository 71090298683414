import React, { useState, useEffect } from "react";
import FilterView from "./FilterView";
import ListPartnersView from "./ListPartnersView";
import * as Bootstrap from "react-bootstrap";

function DisplayContainer(props) {
    const dataLayer = props.dataLayer;
    const [filters, setFilters] = useState({});
    const [selectedFilters, setSelectedFilters] = useState({});
    const [filterText, setFilterText] = useState(undefined);
    const [items, setItems] = useState([]);

    useEffect(() => {
        dataLayer.getContent().then((data) => {
            setFilters(data.filters);
            setItems(data.items);
        })
    }, []);

    const resetFilters = () => {
        setSelectedFilters({})
    }

    const handleChange = (type, value) => {
        setSelectedFilters({ ...selectedFilters, [type]: value })
    };

    const filterItems = (items) => {
        return items.filter(i => {
            const isCategoryFilteredOut = Object.keys(i.categories).every(k => {
                const isFiltered = selectedFilters[k]?.length > 0 ?? false;
                const isMatch = selectedFilters[k]?.every(
                    selectedItem => i.categories[k]?.includes(selectedItem) ?? false)
                    ?? true
                return !isFiltered || isMatch;
            })

            const isTitleFilteredOut = filterText ? !i.title.toLowerCase().includes(filterText.toLowerCase()) : false;
            return isCategoryFilteredOut && !isTitleFilteredOut;
        })
    }



    return (

        <>
            <div className="container ">
                <Bootstrap.Row className="justify-content-between align-items-start mt-5 pt-2  ">
                    <Bootstrap.Col className="col-lg-4  col-12 sticky">
                        <FilterView
                            data={filters} onChange={handleChange} resetFilters={resetFilters} selectedFilters={selectedFilters}
                            onSearchChange={setFilterText}
                        />
                    </Bootstrap.Col>
                    <Bootstrap.Col className="col-lg-8 col-12 ">
                        <div className="list-partners box-shadow p-4 mt-4 mt-lg-0">
                            <ListPartnersView data={filterItems(items)} selectedFilters={filters} />
                        </div>
                    </Bootstrap.Col>
                </Bootstrap.Row>
            </div>


        </>
    )
}

export default DisplayContainer;