import React from "react";
import { default as ReactSelect, components } from "react-select";
import { fieldNameToReadableName } from './../Helpers'



const Option = (props) => {

  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};


const customStyles = {
  option: (provided, state) => ({
    ...provided,

    fontFamily: 'Poppins, sans-serif',
    color: state.isSelected ? "#333" : "",
    paddingBottom: "15px",
    fontSize: 14,
    backgroundColor: state.isSelected ? "#eee" : "",
    textAlign: "left",
    cursor: "pointer",
    borderRadius: 0,
  }),
  // active {
  //   ...styles[':active'],
  //   backgroundColor: !isDisabled
  //     ? isSelected
  //       ? data.color
  //       : color.alpha(0.3).css()
  //     : undefined,
  // },

  container: base => ({
    ...base,
    width: "100%",

  }),
  control: base => ({
    ...base,
    minHeight: 45,
    maxHeight: 150,
    fontSize: 14,
    borderRadius: 0,
    width: "100%",
    textAlign: "left",
    cursor: "pointer",
    border: 'none',
    borderBottom: '1px solid #ddd',
    outline: 'none',
    boxShadow: 'none'
  }),
  dropdownIndicator: base => ({
    ...base,
  }),
  indicatorSeparator: base => ({
    ...base,
    display: "none"
  }),
  valueContainer: base => ({
    ...base,
    padding: 0,
    paddingLeft: 2,
  })
};



const DropDown = (props) => {
  const handleChange = (e) => {
    props.onChange(e.map(v => v.value));
  }

  const selectedValues = props.selected?.map(v => ({ value: v, label: props.data.find(d => d.value === v)?.label })) ?? null

  return (
    <>

      <div className="d-block py-2" data-toggle="popover" data-trigger="focus">
        <ReactSelect
          styles={customStyles}
          options={props.data}
          isMulti
          closeMenuOnSelect={true}
          closeMenuOnScroll={true}
          hideSelectedOptions={false}
          placeholder={fieldNameToReadableName(props.name)}
          components={{
            Option
          }}
          name={props.name}
          onChange={handleChange}
          allowSelectAll={true}
          value={selectedValues}

        />
      </div>
    </>
  )
}

export default DropDown